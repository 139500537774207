// @media (min-width: 540px) {
.scroll--up{
    display: block;
    position: fixed;
    font-size: 42px;
    bottom: 4rem;
    right: 1rem;
    padding: 0.3rem;
    opacity: 0.5;
    background-color: #6ab04c;
    color: #fff;
}

.scroll--up:hover{
    background: none;
    color: #6ab04c;
    opacity: 0.8;
     cursor: pointer;
}

