.pagination {
  list-style: none !important;
  display: flex !important;
  padding-bottom: 6vw !important;
  justify-content: center;
  height: 5rem;
  margin: auto 4rem;
  padding-left: 3vw;
  align-items: center;
  background-color: #fff;
  gap: 2vw;
  li {
    cursor: pointer;
  }

  .arrow_prev {
    margin-right: 7vw;
  }
  .arrow_next {
    margin-left: 7vw;
  }
  .active {
    padding: 1vw 1.5vw;
    color: #fff;
    border-radius: 50%;
    background-color: #6ab04c;
    transition: 500ms all;
  }
}
