.breadcrumbs {
	display: inline-block;
	white-space: nowrap;
	font-size: 12px;
	
	@media (max-width: 560px) {
	    white-space: normal;
		margin-top: -5px;
	}

	&__item {
		display: inline-block;

		@media (max-width: 560px) {
			padding: 5px 0;
		}

		&:after {
			content: '';
			display: inline-block;
			width: 3px;
			height: 3px;
			vertical-align: middle;
			border-radius: 100%;
			background: #d2d2d2;
			margin-right: 13px;
			margin-left: 13px;
		}
	}
	
	a, span {
        text-decoration: none;
		color: #a3a3a3;
	}

	a:hover {
		color: #000;
	}

	svg {
		fill: #cfcfcf;
	}
}
