.inputFormRequest {
  option {
    background-color: #fff !important;
  }
  datalist {
    width: 100% !important;
  }
  option:hover {
    background-color: #f6f6f6 !important;
  }
  input {
    appearance: none !important;
    -webkit-appearance: none !important;
  }

.selectric-scroll{
    li{
        background-color: #fff !important;
    }
    li:hover{
        background-color: #f6f6f6 !important;
    }
}
}

.selectric-js-select-time{
    .selectric{
        height: 3.875rem;
        .label{
            line-height: 3.875rem !important;
        }
        b{
            height: 3.875rem;
        }
        border-radius: 0 !important;
        border: 0 !important;
        background-color: #f6f6f6 !important; 
    }
}
@media (max-width: 768px) {
    .selectric-js-select-time{
        .selectric{
        height: 52px !important;
        .label{
            line-height: 52px !important;
        }
        b{
            height: 52px  !important;
        }
    }
    }
}

.selectric-items {
  height: fit-content !important;
  background-color: #f6f6f6;
  .selectric-scroll {
    height: fit-content !important;
    max-height: 9.8rem !important;
    overflow: auto;
  }
}
.costItem_content {
  .selectric {
    .label {
      margin-right: 40px !important;
    }
  }
}

@media (max-width: 768px) {
  .selectric-js-select-time {
    .selectric {
      height: 52px !important;
      .label {
        line-height: 52px !important;
      }
      b {
        height: 52px !important;
      }
    }
  }
}
.inputFormRequest--items {
  width: 100%;
  margin-bottom: 1rem;
  span {
    cursor: pointer;
  }
  column-gap: 3px;
  .inputFormRequest--item {
    display: inline-flex;
    padding: 4px 10px;
    margin: 0 3px 5px;
    background-color: #6ab04c;
    border-radius: 10px;
    color: #fff;
    align-items: center;
    span {
      border-left: 1px solid #f6f6f6;
      display: flex;
      padding: 1px 2px 1px 5px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
