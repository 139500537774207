.modalMask--inner {
  display: block;
  position: fixed;
  width: 100% !important;
  height: calc(100% + 3rem) !important;
  opacity: 0.5;
  background-color: gray;
  z-index: 200;
  top: -3rem !important;
  left: 0 !important;
}
.modalMask--wrapper {
  border-radius: 32px;
  display: block;
  position: fixed;
  margin: 1rem 5vw;
  z-index: 990 !important;
  opacity: 1 !important;
  height: 95vh;
  top: 0 !important;
  width: 90vw;
  background-color: #fff !important;
  .modalMask--header {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: #6ab04c;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem 1rem;
    span:nth-child(2),
    span:nth-child(3) {
      font-weight: 900;
      font-size: 12px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 0.2rem 0.5rem;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .modalMask--input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0;
    .checkbox {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      margin: auto 0.3rem;
    }
    input {
      width: 40vw;
    }
  }
  .modalMask--data {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    height: 50vh;
    padding: 0.5rem 1rem;
    overflow-y: scroll;
    border-bottom: 1px solid rgb(218, 218, 218);
  }
  .modalMask--footer {
    display: grid;
    justify-content: flex-end;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 15rem));
    gap: 1rem;
    flex-direction: row;
    align-items: center;
    padding: 3vh 1rem;
  }
}

@media (max-width: 768px) {
  .modalMask--wrapper {
    left: 0 !important;
    top: 0 !important;
    margin: 0;
    height: 100vh !important;
    width: 100vw !important;
    border-radius: 0 !important;
    .modalMask--header {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .modalMask--input {
    input {
      width: 80vw !important;
    }
  }
}
@media (min-width: 769px) {
  .modalMask--input {
    input {
      width: 50vw !important;
    }
  }
}
@media (max-width: 540px) {
  .modalMask--input {
    .checkbox {
      margin-left: 14px !important;
    }
    input {
      width: 88vw !important;
    }
  }
  .modalMask--data {
    height: 56vh !important;
  }
  .modalMask--footer {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr)) !important;
    gap: 0.3rem !important;
    padding: 3vh 2vw !important;
    right: 0.5rem;
  }
  .modalMask--header {
    padding-left: 15px !important;
    .btn--close {
      opacity: 0 !important;
    }
  }
}
@media (max-width: 340px) {
  .modalMask--data {
    height: 50vh !important;
  }
  .modalMask--footer {
    padding: 1vh 5vw !important;
  }
}
