body {
    overflow-x: hidden !important;
}
.report--table {
    color: #fff;
    border-collapse: collapse;
    border: 2px solid white;
    width: 90vw !important;
    margin: 1rem auto 5rem;
    font-size: 0.8rem;
    thead {
        border-bottom: 1px #f6f6f6 solid;
        background-color: #6ab04c;
        height: 2rem;
    }
    tr {
        border-bottom: 1px #e6e6e6 solid;
        padding: 0.5rem 0 !important;
        display: grid;
        grid-template-columns: 3vw 16vw 10vw 10vw 14vw 12vw 10vw 7vw 12vw;
        th{
            text-align: start !important;
        }
    }
    tbody {
        color: #6ab04c !important;
        display: grid;
        row-gap: 0.5rem;
        tr {
            color: #000;
            font-weight: 600;

        }
    }
}
@media (max-width: 1024px) {
    .report--table {
        width: 98vw !important;
        font-size: 0.7rem;
        tr {
            grid-template-columns: 3vw 15vw 11vw 7.5vw 14vw 12vw 7vw 9vw 13vw;
            th:nth(4){
                text-align: end !important;
            }
        }
    }
}
