.alert{
    display: flex;
    justify-content: center;

.alert-show{
    font-family: inherit;
    height: 2rem;
    width: 50vw;
    max-width: 25rem;
    padding: 2.5rem 1rem 2rem 3rem;
    z-index: 100;
    position: fixed;
    opacity: 0.9;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: #6ab04c;
    animation: show_on 3000ms ease-in-out;
    animation: top 500ms ease-in-out;
    border: #fff 1px solid !important;
    }

@keyframes show_on{
    0% {
        opacity: 0;
    }
    50%{
        opacity: 0.9;
    }
    90%{
        opacity: 0.9;
    }
    100%{
        opacity: 0 !important;
    }
}
@keyframes top {
    0% {
        top: 6rem
    }
}
}